import React from 'react'
import { Parallax } from 'react-parallax';
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Hero from '../components/Hero';
import SocialShare from '../components/SocialShare';
import CaseStudyFragment from '../components/CaseStudyFragment';
import useSiteMetadata from '../components/SiteMetadata';

export const CaseStudyTemplate = ({
  content,
  contentComponent,
  image,
  logo,
  title,
  helmet,
  testimonial,
  href,
  prev,
  next
}) => {
  const PageContent = contentComponent || Content
  
  return (
    <>
      {helmet || ''}

      <Parallax bgImage={image} strength={500}>
        <div className="cover-container">
            <Hero className="cover">
                  <strong className="cover__content-type">Case study</strong>
                  <h1 className="h1-alt">
                    {title}
                  </h1>
                  <img className="cover__brand" src={logo} alt=""/>
            </Hero>
        </div>
      </Parallax>

      <div className="section">
          <div className="section__inner">
              <div className="container">

                  <div className="content-container content-container--long-form">
                      <PageContent className="content" content={content}/>

                      { testimonial &&
                        <blockquote className="testimonial">
                          <PageContent content={testimonial.html}/>
                          <footer>
                            &mdash; <cite>{testimonial.frontmatter.citation}</cite>
                        </footer>
                      </blockquote>
                      }

                      <SocialShare url={href} title={title}/>
                  </div>

              </div>
          </div>
      </div>

      { prev &&
        <CaseStudyFragment bgImg={prev.frontmatter.image.publicURL} slug={prev.fields.slug} title={prev.frontmatter.title} logo={prev.frontmatter.logo.publicURL} isStandalone={!next} meta="Next up"/>
      }
      
      { next &&
        <CaseStudyFragment bgImg={next.frontmatter.image.publicURL} slug={next.fields.slug} title={next.frontmatter.title} logo={next.frontmatter.logo.publicURL} isStandalone={!prev} meta="Next up"/>
      }
    </>
  )
}

CaseStudyTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const CaseStudy = ({ data, location }) => {
  const { page, footerData, headerData, prev, next } = data;
  const { title } = useSiteMetadata();

  return (
    <Layout footerData={footerData} headerData={headerData}>
      <CaseStudyTemplate
        title={page.frontmatter.title}
        content={page.html}
        contentComponent={HTMLContent}
        image={page.frontmatter.image.publicURL}
        logo={page.frontmatter.logo.publicURL}
        href={location.href}
        testimonial={page.fields.testimonials && page.fields.testimonials[0]}
        helmet={
          <Helmet titleTemplate={`%s | Work | ${title}`}>
            <title>{`${page.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            />
          </Helmet>
        }
        prev={prev}
        next={next}
      />
    </Layout>
  )
}

CaseStudy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const query = graphql`
  query CaseStudyByID($id: String!, $prev: String!, $next: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
        testimonials {
          html
          frontmatter {
            name
            citation
          }
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          publicURL
        }
        logo {
          publicURL
        }
        testimonial
      }
    }
    prev: markdownRemark(fields: { slug: { eq: $prev } }) {
      id
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        image {
          publicURL
        }
        logo {
          publicURL
        }
      }
    }
    next: markdownRemark(fields: { slug: { eq: $next } }) {
      id
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        image {
          publicURL
        }
        logo {
          publicURL
        }
      }
    }
    ...LayoutFragment
  }
`

export default CaseStudy