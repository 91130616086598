import React from 'react';
import PropTypes from 'prop-types';

const CaseStudyFragment = ({ bgImg, slug, title, logo, isStandalone, meta}) => {
    return (
        <article className={['case-study', isStandalone ? 'case-study--standalone' : null ].join(' ')} style={{ backgroundImage: `url(${bgImg})` }}>
            <a href={slug}>
                <header>
                    <strong className="case-study__meta">{meta}</strong>
                    <h4 className="case-study__title">{title}</h4>
                </header>
                <img className="case-study__brand" src={logo} alt=""/>
            </a>
        </article>
    )
};

CaseStudyFragment.defaultProps = {
    meta: 'Case study'
}

CaseStudyFragment.propTypes = {
    
};

export default CaseStudyFragment;