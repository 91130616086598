import React from 'react';
import PropTypes from 'prop-types';

const SocialShare = ({url, title}) => {
    const titleEncoded = encodeURIComponent(title);
    const urlEncoded = encodeURIComponent(url);

    return (
        <dl className="social-share">
            <dt>Share this:</dt>
            <dd>
                <ul>
                    <li><a className="social-share__email" href={`mailto:?body=${urlEncoded}`}><span className="is-visually-hidden">Email</span></a></li>
                    <li><a className="social-share__twitter" href={`https://twitter.com/share?url=${urlEncoded}&amp;text=${titleEncoded}`}><span className="is-visually-hidden">Twitter</span></a></li>
                    <li><a className="social-share__linkedin" href={`http://www.linkedin.com/shareArticle?url=${urlEncoded}&amp;title=${titleEncoded}`}><span className="is-visually-hidden">LinkedIn</span></a></li>
                    <li><a className="social-share__googleplus" href={`https://plus.google.com/share?url=${urlEncoded}`}><span className="is-visually-hidden">Google Plus</span></a></li>
                </ul>
            </dd>
        </dl>
    )
};

SocialShare.propTypes = {
    
};

export default SocialShare;